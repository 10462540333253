@import "~consumer/styles/less/modules/variables.less";
@import "~consumer/styles/less/modules/mixins.less";

.root {
  .tile-root();
}

.link {
  .tile-image();

  display: flex;
  flex-direction: column;
}

.preview {
  border-radius: @tile-border-radius;
}

.description {
  display: inline-block;
  line-height: 1.8em;
  margin-top: 5px;
  text-align: left;
  width: 100%;

  @media (max-width: 500px) {
    font-size: 1.0em;
  }


  :global(.user-highlight) {
    margin-right: 10px;
    vertical-align: -12px;

    .user-highlight-name {
        font-size: 1.1em;
    }
  }
}

.stats {
  align-items: center;
  bottom: 0;
  color: @weplayed-overlay-text-light;
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.9em;
  font-weight: 800;
  justify-content: space-between;
  left: 0px;
  padding: 3px 10px 1px;
  position: absolute;
  right: 0;

  .game {
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-direction: row;

    span {
      margin: 0 5px;
    }
  }

  &.fade {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 10%, rgba(0,0,0,0.7) 100%);
  }

  > span {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}
