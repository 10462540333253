@import "~consumer/styles/less/modules/variables.less";

.root {
  align-items: center;
  background-color: @weplayed-background-semitransparent;
  border-radius: 10px;
  border: none;
  color: @body-color;
  display: flex;
  padding: 5px;

  #media-breakpoint-down(@layout-toggle, {
    &.collapse span {
      display: none;
    }
  });

  span {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 8px;
    text-transform: uppercase;
  }

  svg {
    &.liveOnline {
      fill: red;
    }

    .liveOffline {
      fill: grey;
    }
  }
}
