@import '~consumer/styles/less/modules/variables.less';

@game-filter-height: 2.25em;
@game-filter-controls-height: 6em;

:export {
  name: --game-filter-height;
}

:root {
  --game-filter-height: @game-filter-height;
}

.button {
  background-color: transparent;
  border-radius: 0.25em;
  border: none;
  margin-left: 2px;
  padding: 0;
  width: 2.3em;

  svg {
    fill: @body-color;
    height: 75%;
    width: 75%;
  }
}

.controls {
  display: none;
  flex-direction: column;

  #media-breakpoint-up(@layout-toggle, {
    flex-direction: row;
  });
}

.root {
  background-color: @body-bg;
  box-sizing: border-box;
  min-height: @game-filter-height;
  max-height: var(--game-filter-height);
  padding-left: var(--game-controls-padding);
  padding-right: calc(var(--game-controls-padding) - 0.4em);

  &.expanded {
    .button {
      background-color: fade(@body-color, 20%);
    }

    .controls {
      display: flex;
    }
  }
}

.disabled {
  touch-action: none;
  pointer-events: none;
  opacity: .3;
}

.filterTag {
  background-color: @weplayed-blue-dark;
}

.filterText {
  background-color: @body-bg;
  color: @body-color;
}

.filterTeam {
  background-color: black;
}

.filterPlayer {
  background-color: black;
}

.filterControl {
  background-color: darken(@body-color, 10%);
  color: @body-bg;

  svg {
    fill: @body-bg;
  }
}

.search {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;  // button outline
}

.input {
  flex: 1;
  margin-right: 0.5em;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  flex: 1;

  #media-breakpoint-up(@layout-toggle, {
    flex: 2
  });

  > li {
    list-style-type: none;
    margin: 0;
    padding: 0.25em 2em;
    width: 50%;
    white-space: pre;
  }
}

.tags {
  flex: 1;
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    padding-top: 0.5em;
  }

  select {
    background-color: @body-bg;
    border-radius: @border-radius;
    color: @body-color;
    width: calc(100% - 2em);
  }

  #media-breakpoint-up(@layout-toggle, {
    width: 33%;
  });
}