.ads {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 1em 1em;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.ad {
  max-width: 100%;

  img {
    max-height: 3em;
    max-width: 100%;
  }
}
