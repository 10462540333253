@import '~consumer/styles/less/modules/variables.less';

.button {
  align-items: center;
  color: @body-bg;
  border: none;
  background-color: transparent;
  display: flex;
  padding: 2px 2px;

  &:hover {
    svg {
      fill: fade(@body-bg, 50%);
    }
  }

  svg {
    fill: @body-bg;
    height: 24px;
    width: 24px;
  }

  // > div {
  //   height: 24px;
  //   width: 24px;
  // }
}

.withText {
  svg {
    margin-right: 5px;
  }
}

.root {
  position: relative;
}

.menu {
  width: 30px;
  z-index: 20000 !important;

  :global(.popover-content) {
    display: flex;
    flex-direction: row;
  }
}

.buttonVariant {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}