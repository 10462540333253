@import '~consumer/styles/less/modules/variables.less';

@animation-duration: 0.2s;
@icon-shift: 5px;
@logo-height: 15px;
@scroll-height: 200px;

.filters {
  display: inline-block;
  margin-right: 30px;
}

.root {
  margin-top: 10px;

  &.loading {
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
  }

  button {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 22px;
    margin-bottom: 10px;
    margin-right: 10px;

    .join {
      color: white;
      text-align: center;
      width: 20px;
    }

    .acronym {
      margin: 0 @icon-shift;

      & + img {
        height: 13px;
        width: 13px;
      }

      #media-breakpoint-up('sm', {
        display: none;
      });
    }

    & > .logo {
      margin-right: 5px;
      object-fit: contain;

      #media-breakpoint-down('sm', {
        display: none;
      });
    }

    svg, img {
      height: 22px;
      width: 22px;
    }
  }
}

.pad {
  background-color: @game-headline-background;
  border-radius: 5px;
  display: none;
  margin-bottom: 10px;
  margin-right: 5px;
  max-width: 600px;
  overflow: hidden;
  padding: 0;

  &.visible {
    display: block;
  }

  dl {
    margin: 20px;
  }

  dt {
    box-shadow: 0 1px @body-color;
    text-transform: uppercase;
  }

  dd {
    margin-bottom: 30px;
    margin-top: 10px;

    .scrollarea-content {
      touch-action: auto !important;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      ul {
        li {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-bottom: 2px;
        }


        img {
          height: @logo-height;
          margin-right: @icon-shift;
        }

        span {
          align-items: center;
          display: flex;

          img {
            height: @logo-height * 0.7;
            margin-left: 5px;
            vertical-align: -2px;
          }
        }
      }
    }
  }

  li {
    // margin-right: 10px;
    user-select: none;

    &:not(.nohover) {
      cursor: pointer;

      &:hover {
        background-color: fade(@body-color, 5%);
      }
    }

    strong {
      display: block;
      margin-top: 10px;
    }

    &.selected {
      color: @weplayed-blue-light;
    }
  }

  dt:nth-child(1) {
    order: 1;
  }

  dt:nth-child(3) {
    order: 2;
  }

  dt:nth-child(5) {
    order: 3;
  }

  dd:nth-child(2) {
    order: 4;
  }

  dd:nth-child(4) {
    order: 5;
  }

  dd:nth-child(6) {
    order: 6;
  }

  @media(min-width: @grid-float-breakpoint) {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height @animation-duration ease-out;

    &.visible {
      // bigger than ever possible value
      max-height: @scroll-height + 100px;
      transition: max-height @animation-duration ease-in;
    }

    dl {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    dt, dd {
      width: 30%;
    }

    dd {
      margin-bottom: 0;

      > ul {
        max-height: @scroll-height;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          box-shadow: inset 0 0 5px @weplayed-background-secondary;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: @weplayed-secondary-cta;
          border-radius: 10px;
        }
      }
    }
  }
}
