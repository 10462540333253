@import "~consumer/styles/less/modules/variables.less";

.position {
  align-items: center;
  display: flex;

  h2 {
    font-size: 2em;
    margin: 0;

    @media(max-width: 500px) {
      font-size: 1.5em;
    }
  }

  .logo {
    margin-right: 10px;
  }
}
