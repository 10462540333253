@import '~consumer/styles/less/modules/variables.less';

:root {
  --game-controls-padding: 1em;
}

.root {
  background-color: @weplayed-background-secondary;
  margin: -@app-page-padding 0;

  #media-breakpoint-up(@layout-toggle, {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: calc(100vh - 2 * @tiny-hav-size + 1px); // 1px prevents scroll bar from disappearing so no jumps
  });
}

.video {
  position: sticky;
  top: 0;
  z-index: 5;

  #media-breakpoint-up(@layout-toggle, {
    top: @tiny-hav-size;
    width: 66.66%;
  });
}

.controls {
  #media-breakpoint-up(@layout-toggle, {
    width: 33.33%;
  });
}

.headline {
  #media-breakpoint-up(@layout-toggle, {
    position: sticky;
    top: @tiny-hav-size;
    z-index: 4;
  });
}

.annotations {
  display: block;
}

.filter {
  background-color: @body-bg;
  padding-top: var(--game-controls-padding);

  #media-breakpoint-up(@layout-toggle, {
    position: sticky !important;
    top: calc(@tiny-hav-size + var(--game-headline-height));
    z-index: 3;
  });
}

.hub {
  background-color: @body-bg;
  padding-top: var(--game-controls-padding);
  position: sticky;
  top: calc(100vw * @video-proportions + var(--game-timeline-height));
  z-index: 2;

  #media-breakpoint-up(@layout-toggle, {
    top: calc(@tiny-hav-size + var(--game-headline-height) + var(--game-filter-height));
  });
}

.entry {
  #media-breakpoint-up(@layout-toggle, {
    position: sticky;
    z-index: 1;
    top: calc(
      @tiny-hav-size
      + var(--game-headline-height)
      + var(--game-filter-height)
      + var(--game-controls-padding) * 2
      + var(--game-hub-header-height)
      + 1px;
    );
  });
}