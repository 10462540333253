.logo {
  filter: drop-shadow(0px 0px 1px #fff);
  height: 1.2em;
  margin-right: 0.4em;

  ~ .logo {
    margin-left: 0.4em;
    margin-right: 0;
  }
}

.dot {
  position: relative;
  height: 1em;
  width: 1.5em;

  &::before {
    align-items: center;
    content: "・";
    display: flex;
    font-size: 1em;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0.5;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
}