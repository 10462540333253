@import '~consumer/styles/less/modules/variables.less';

.root {
  max-width: 60vw;
  width: 60vw;

  #media-breakpoint-down('md', {
    width: 100vw;
    max-width: 100vw;
  });

  :global(.modal-content) {
    height: calc(60vw * @video-proportions);
    background-color: transparent !important;
    overflow: visible !important;
    position: relative;

    #media-breakpoint-down('md', {
      height: calc(100vw * @video-proportions);
    });
  }

  iframe {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  svg {
    cursor: pointer;
    position: absolute;
    top: -60px;
    right: -60px;

    #media-breakpoint-down('md', {
      right: 30px;
    });
  }
}
