@import '~consumer/styles/less/modules/variables.less';

@border: 2px;
@offset: 10px;
@selected: escape('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24" fill="@{body-color}" stroke="@{body-color}"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>');
@size: 24px;
@time: 0.1s;

.root {
  position: relative;
}

.select {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  & ~ span {
    border-radius: 50%;
    border: solid @border @body-color;
    height: @size;
    left: @offset;
    pointer-events: none;
    position: absolute;
    top: @offset;
    touch-action: none;
    width: @size;
  }

  &:checked ~ span {

    background-color: @weplayed-blue-light;
    background-image: url("data:image/svg+xml;utf8,@{selected}");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    border-color: @weplayed-blue-light;
  }
}
