
:global {
  html, body {
    min-height: 100vh;
  }

  #app {
    min-height: 100vh;
  }
}
