@import "~consumer/styles/less/modules/variables.less";

.root {
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: transparent;
  color: unset;
  transition: opacity 200ms;

  svg {
    fill: currentColor;
    width: 24px;
    height: 24px;
  }

  &.withActive {
    border-bottom: solid 2px transparent;
    transition: border-bottom 0.3s linear;
  }

  &[disabled],
  &[disabled]:hover {
    opacity: 0.2;
  }

  &.active {
    border-bottom-color: @weplayed-blue-light;
  }
}
