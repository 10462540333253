@import "~consumer/styles/less/modules/variables.less";

@title-font-desktop: 1.5rem;
@title-font-mobile: 1.2rem;

@subtitle-font-desktop: 1.2rem;
@subtitle-font-mobile: 1rem;

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 20px;
  padding: 0 20px;
  width: 100%;

  > * {
    margin-top: 10px !important;
  }

  .logo {
    height: 24px;
    margin-right: 5px;
  }

  h1, h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  h1 {
    font-size: @title-font-mobile;
    margin: 0;
    order: 1;
    padding: 0;
    white-space: nowrap;
  }

  h2 {
    font-size: @subtitle-font-mobile;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  .sport {
    display: flex;
    flex-direction: row;
    justify-content: center;
    order: 3;
    width: 100%;
  }

  .follow {
    margin-left: 20px;
  }

  .teams {
    align-items: center;
    display: flex;
    justify-content: center;
    order: 2;
  }

  .search {
    width: 100%;
    form {
      max-width: inherit;
    }
  }

  #media-breakpoint-up(md, {
    .logo {
      height: 30px;
    }

    h1 {
      font-size: @title-font-desktop;
    }

    h2 {
      font-size: @subtitle-font-desktop;
    }

    .sport {
      order: 1;
      width: auto;
    }
  });

  #media-breakpoint-up(xl, {
    width: 60%;
  });
}

.content {
  margin: 0 25px;

  hr {
    margin-bottom: 0;
  }

  :global(.slick-list) {
    padding-top: 10px;
  }
}

.pinOff {
  opacity: .5;
}
