@import '~consumer/styles/less/modules/variables.less';

.tabs {
  margin-bottom: 0.5em;
  margin-top: 2em;

  #media-breakpoint-down('md', {
    margin-top: 0.5em;
  });
}

.tab {
  text-transform: uppercase;

  #media-breakpoint-down('md', {
    font-size: 14px;
  });

  b {
    #media-breakpoint-down('md', {
      display: block;
      text-align: center;
    });
  }
}

.select {
  display: inline-block;
}

.empty {
  margin: 40px 0;
}