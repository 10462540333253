@import '~consumer/styles/less/modules/variables.less';

.headerContainer {
  max-width: inherit;
}

.tabsContainer {
  margin-top: 1em;
  margin-bottom: 1em;
}

.tabs {
  @media(max-width: 768px) {
    justify-content: space-between;
  }
}

.tab {
  font-size: 28px;
  min-width: auto;
  text-transform: uppercase;

  @media(max-width: 768px) {
    font-size: 12px;
    flex-grow: 0;

    > span > span {
      padding: 6px 0;
    }
  }
}

.headerPositioner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  :global(.channel-tile) {
    width: auto;
  }
}

.headerInfo {
  font-size: 125%;
  margin: 20px 20% -10px;
  text-align: center;

  a {
    color: @weplayed-blue-light;
  }

  @media(max-width: 500px) {
    font-size: 100%;
    margin: 10px 10px -10px;
  }
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
