@keyframes bounce {
  0% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
};

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  touch-action: none;
  pointer-events: none;
  transition: opacity 200ms linear;

  div {
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url('~images/logo-symbol-open-white.svg');
    background-size: contain;
  }

  &.loading {
    opacity: 1;

    div {
      animation: bounce 2s cubic-bezier(0.785, 0.135, 0.150, 0.860) infinite both;
    }
  }
}
