@import '~consumer/styles/less/modules/variables.less';
@import '~consumer/styles/less/modules/mixins.less';

.root {
  .background-special-gradient();

  align-items: center;
  border-top: solid 3px @gradient-special-to;
  bottom: 0;
  color: white;
  display: flex;
  font-size: 1.5em;
  font-weight: 700;
  justify-content: space-between;
  left: 0;
  padding: 1em 4em;
  position: sticky;
  text-transform: uppercase;
  transition: color .3s, border-top-color .3s, padding .3s;
  width: 100%;

  &.gray::before {
    .background-gray-gradient();

    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s;
    width: 100%;
    z-index: -1;
  }

  &.gray.toggle {
    border-top-color: @gradient-gray-to;
    color: @weplayed-foreground;
    padding-bottom: 0.4em;
    padding-top: 0.4em;

    &::before {
      opacity: 1;
    }
  }

  svg {
    fill: #c2c2c2;
    height: 1.5em;

    @media (max-width: 768px) {
      display: none;
    }
  }

  span {
    flex: 1;
    display: flex;

    &:last-child {
      justify-content: flex-end;
    }
  }

  a {
    background-color: @weplayed-accent;
    border-radius: 500em;
    box-shadow: @weplayed-foreground 0 3px 3px;
    font-family: @weplayed-text-font;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 6px;
    padding: 0.4em 1.5em;
  }

  @media (max-width: 1200px) {
    font-size: 1em;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}
