@import '~consumer/styles/less/modules/variables.less';

.channel-tile {
  align-items: center;
  color: @body-color;
  display: flex;
  justify-content: flex-start;
  margin-top: 1em;
  width: 100%;
  max-width: 500px;

  &:hover {
    color: @body-color;
  }

  @media (max-width: 768px) {
    margin-top: 0.5em;

    .user-avatar {
      height: 100px;
      width: 100px;
    }
  }

  .avatar-container {
    flex-shrink: 0;
  }

  .description {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 40px;

    @media (max-width: 768px) {
      margin-left: 20px;
    }

    .username-and-edit {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .edit-button {
      margin-left: 20px;
    }

    .username {
      .verified(@fontSize) {
        span.verified {
          @size: @fontSize * 0.7;

          background: url(~images/verified.png) left center no-repeat;
          background-size: @size;
          font-size: @fontSize * 0.5;
          height: @size;
          margin-left: 20px;
          position: relative;
          width: @size;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(~images/verified@2x.png);
          }

          span {
            height: @size;
            left: @size;
            overflow: hidden;
            padding-left: 5px;
            position: absolute;
            top: 0;
            transition: width 200ms ease-in;
            width: 0;
          }

          &:hover {
            span {
              width: 90px;
            }
          }
        }
      }

      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 30px;
      justify-content: flex-start;

      .verified(30px);

      @media (max-width: 768px) {
        font-size: 20px;
        .verified(20px);
      }
    }

    .full-name {
      font-size: 20px;

      @media (max-width: 768px) {
        font-size: 13px;
      }
    }

    .stats-row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      width: 100%;
    }

    .stat {
      align-items: flex-end;
      display: flex;
      flex-wrap: nowrap;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .buttons-row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
    }

    .stats-row {
      @media(max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .stat-count {
      font-size: 28px;
      margin-right: 5px;
      position: relative;
      top: 4px;

      @media (max-width: 768px) {
        font-size: 18px;
        top: 2px;
      }
    }

    .stat-label {
      color: @weplayed-secondary-cta;
      font-size: 14px;

      @media (max-width: 768px) {
        font-size: 11px;
      }
    }

    .playlist-icon {
      height: 40px;
      position: relative;
      top: 10px;
      width: 40px;

      @media (max-width: 768px) {
        height: 25px;
        top: 5px;
        width: 25px;
      }
    }
  }

  &.normal {
    max-width: 380px;

    .avatar-container {
      height: 100px;
      width: 100px;
    }

    .description {
      margin-left: 20px;
    }

    .username {
      font-size: 2rem;
    }

    .stat-count {
      font-size: 1.6rem;
      top: 2px;
    }

    .stat-label {
      font-size: 1.1rem;
    }

    .playlist-icon {
      margin-right: 4px;
      top: 5px;
    }
  }

  &.small {
    margin-bottom: 15px;
    width: @tile-desktop-small-width;

    @media(max-width: 500px) {
      width: @tile-mobile-small-width;
    }

    .avatar-container {
      height: 70px;
      width: 70px;
    }

    .description {
      margin-left: 10px;
    }

    .username {
      font-size: 1rem;
    }

    .stat-count {
      font-size: 0.8rem;
      top: 1px;
    }

    .stat-label {
      font-size: 0.8rem;
    }

    .playlist-icon {
      height: 12px;
      margin-right: 2px;
      width: 12px;
      top: 2px;
    }
  }
}
