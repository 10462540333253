@import './variables.less';
@import './mixins.less';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Saira:wght@300;500;800&display=swap');

@font-face {
  font-family: "DigitalDream";
  src: url("~fonts/DIGITALDREAM.woff2") format("woff2");
}

body {
    font-size: 14px;
    line-height: 1.42857143;
}

html {
  scroll-behavior: smooth;
}

.btn-normal {
    width: 10em;
}

.btn-small {
    width: 7em;
}

.btn-large {
    width: 15em;
}

.dropdown-button {
    margin-left: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-menu.show {
    opacity: 1 !important;
}

.mono-color-link {
    .no-hover-effect();

    > a {
        .no-hover-effect();
    }
}

.mono-color-link-black {
    .no-hover-effect(black);

    > a {
        .no-hover-effect(black);
    }
}

// Remove the focus glow:
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    // outline: 0;
    // outline-color: transparent;
    // outline-width: 0;
    // outline-style: none;
    outline: dotted 1px #e8e8e8;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

.center-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 200;
}

.loading-image {
    position: absolute;
    display: block;
    left: 50%;
    margin: 25px 0 0 -25px;
    z-index: 201;

    &.loading-image-static {
        position: static;
        margin: 25px auto;
    }

    &.loading-image-fixed {
        position: fixed;
        left: calc(50vw - 25px);
        top: calc(50vh - 25px);
        margin: 0;
    }
}

.comments-container {
    width: 95%;
    margin: 0 auto;
}

.index {
    color: red;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: @weplayed-special-font;
    font-weight: 800;
}

h1 {
    font-size: 2.5em;

    @media (max-width: 768px) {
        font-size: 2.0em;
    }
}

h2 {
    font-size: 1.8em;

    @media (max-width: 768px) {
        font-size: 1.3em;
    }
}

h3 {
    font-size: 1.5em;

    @media (max-width: 768px) {
        font-size: 1.2em;
    }
}

hr {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-secondary {
    color: @body-color;
}

.btn-default {
    background-color: @weplayed-secondary-cta;
}

.moment-presenter-description {
    font-family: @weplayed-text-font;
    font-size: 1.2em;

    @media (max-width: 500px) {
        font-size: 1.0em;
    }

    //margin-top: 5px;
    //height: 100px;
    display: inline-block;
    text-align: left;
    width: 100%;
    line-height: 1.8em;

    .user-highlight {
        vertical-align: -10px;
        margin-right: 10px;

        .user-highlight-name {
            font-size: 1.1em;
        }
    }
}

.modal {
    color: @weplayed-modal-text;
}

.modal-title {
    font-size: 20px;
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: space-around;
}

.modal-backdrop-blackout {
    background-color: black;
    width: 100%;
    height: 200vh;
}

.modal-backdrop.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
    z-index: 1000;
}

::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;

    /* Now your own custom styles */
    height: 14px;
    width: 14px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;

    /* icon size */
    background-size: 14px;
}

.heart-button-div, .pin-button-div {
    //border: 1px dotted green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.heart-button-counter {
    position: relative;
    left: 3px;
    top: -3px;
}

.pin-button-div {
    position: relative;
    left: 3px;
    top: 0px;
}

.heart-liked {
    color: @heart-liked-color;
}

.pin-pinned {
    color: @pin-pinned-color;
}

.heart-editable, .pin-editable {
    cursor: pointer;
}

.moment-actions-bar-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;

    > button:hover {
        opacity: 50%;
    }

    svg {
        fill: currentColor;
        height: 24px;
        width: 24px;
    }

    > * {
        min-width: 30px;
    }

    .comments-button {
        span {
            position: relative;
            left: 3px;
            top: -10px;
        }
    }
}

input[type="range"] {
    -webkit-appearance: none;
    background-color: transparent;
    cursor: pointer;
    padding: @input-range-height / 2 0;
    height: @input-range-track-size;
    width: 100%;
}

input[type="range"]:focus {
    outline: dotted 1px #e8e8e8;
}

// webkit
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: @input-range-track-size;
    cursor: pointer;
    background: var(--dark);
}

input[type="range"]::-webkit-slider-thumb {
    height: @input-range-thumb-size;
    width: @input-range-thumb-size;
    border-radius: 50%;
    background: @body-color;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -(@input-range-thumb-size - @input-range-track-size) / 2;
}

input[type="range"]:focus::-webkit-slider-thumb {
    background: @weplayed-blue-light;
}

// firefox
input[type="range"]::-moz-range-track {
    width: 100%;
    height: @input-range-track-size;
    cursor: pointer;
    background: var(--dark);
}

input[type="range"]::-ms-track {
    width: 100%;
    height: @input-range-track-size;
    cursor: pointer;
    background: transparent;
}

input[type="range"]::-moz-range-thumb {
    height: @input-range-thumb-size;
    width: @input-range-thumb-size;
    border-radius: 50%;
    background: @body-color;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -(@input-range-thumb-size - @input-range-track-size) / 2;
}

input[type="range"]:focus::-moz-range-thumb {
    background: @weplayed-blue-light;
}

// ie-edge
input[type="range"]::-ms-track {
    width: 100%;
    height: @input-range-track-size;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: var(--dark);
}

input[type="range"]::-ms-fill-upper {
    background: var(--dark);
}

input[type="range"]::-ms-thumb {
    height: @input-range-thumb-size;
    width: @input-range-thumb-size;
    border-radius: 50%;
    background: @body-color;
    cursor: pointer;
    margin-top: -(@input-range-thumb-size - @input-range-track-size) / 2;
}

input[type="range"]:focus::-ms-thumb {
    background: @weplayed-blue-light;
}

svg[id^='mdi-'] {
    fill: @body-color;
}

.no-mobile {
    @media only screen and (max-device-width: 768px) {
        display: none !important;
    }
}

.no-mobile-interaction {
    @media only screen and (max-device-width: 768px) {
        pointer-events: none !important;
        touch-action: none !important;
    }
}