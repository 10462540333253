@import '~consumer/styles/less/modules/variables.less';

.root {
  button img {
    margin-left: 10px;
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  input {
    width: 100%;
  }

  button {
    margin-left: 10px;
    width: 120px;
  }
}

.collections {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 20px;
  overflow-y: auto;
  max-height: 50vh;
  min-height: 100px;
  position: relative;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px @weplayed-background-secondary;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: @weplayed-secondary-cta;
    border-radius: 10px;
  }
}

.loading {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #ffffffaa;
  display: flex;
  align-items: center;
  justify-content:  center;

  svg {
    height: 50px;
    width: 50px;
    fill: @weplayed-blue-light;
  }
}