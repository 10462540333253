@import '~consumer/styles/less/modules/variables.less';

@blocks-margin: 1em;

:root {
  --footer-height: 195px;

  #media-breakpoint-down(@layout-toggle, {
    --footer-height: 165px;
  });
}

.root {
  background-color: @footer-background;
  height: var(--footer-height);
  margin-top: 3em;
  position: relative;
  width: 100%;
  z-index: 200;

  #media-breakpoint-up(@layout-toggle, {
    &.tiny {
      height: @tiny-hav-size;
      margin-top: 0;

      .container {
        max-height: @tiny-hav-size;
        bottom: 0;
        overflow: hidden;
      }

      &:hover {
        .container {
          max-height: var(--footer-height);
          transition: max-height .3s;
        }
      }
    }
  });
}

.container {
  align-items: stretch;
  background-color: @footer-background;
  display: flex;
  flex-wrap: wrap;
  font: @footer-font;
  height: var(--footer-height);
  justify-content: space-between;
  max-height: var(--footer-height);
  position: absolute;
  transition: max-height .3s .3s;
  white-space: nowrap;
  width: 100%;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 0.8em;
  });

  svg {
    fill: @footer-color;
  }

  ul:not(:last-child) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style-type: none;
    margin: @blocks-margin;
    padding: 0;

    &::before {
      color: @footer-header-color;
      content: attr(aria-label);
      margin-bottom: 0.5em;
    }

    li {
      margin: 0 0 0.2em 0;
    }

    &.social {
      flex-direction: row;

      &::before {
        display: none;
      }

      #media-breakpoint-down(@layout-toggle, {
        flex-direction: column;
      });

      li {
        margin-right: 1em;

        svg {
          height: 2.5em;
          width: 2.5em;

          #media-breakpoint-down(@layout-toggle, {
            height: 1.5em;
            width: 1.5em;
          });
        }
      }
    }
  }

  div {
    background-color: @weplayed-background;
    font: @footer-copyright-font;
    padding: 1em 0;
    text-align: center;
    width: 100%;

    #media-breakpoint-down(@layout-toggle, {
      padding: 0.5em 0;
    });
  }

}

.logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5vw;
  margin: @blocks-margin;

  #media-breakpoint-down(@layout-toggle, {
    display: none;
  });

  svg {
    width: 80px;
    fill: #000;
  }

  span {
    padding: 0.5em;
    font-size: x-small;
    opacity: 0.5;
  }
}
