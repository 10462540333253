@import '~consumer/styles/less/modules/variables.less';

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 1.7em;
  border-radius: 5px;
  position: relative;
  background-color: @body-color;
  cursor: text;
  padding: 0 0 0.2em 0.2em;

  &.withVariants:focus-within {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .suggestions {
      display: block;
    }
  }
}

.tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0.2em 0.2em 0 0;
  margin-left: 0;

  svg {
    width: 1em;
    height: 1em;
    margin-left: 0.2em;
  }
}

.input {
  background: transparent;
  border: none;
  outline: none;
  margin: 0.2em 0 0;
  color: @body-bg;
  font-size: 1.2em;
  height: 1.5em;
  padding: 0;
  min-width: 50px;
  max-width: 100%;
  display: inline-block;
  white-space: pre;
  line-height: 1.5em;
  word-break: normal;
}

.suggestions {
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  background-color: @body-color;
  padding: 0;
  margin: 0;
  border-top: solid 1px gray;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
  z-index: 20;

  > li {
    padding: 0 0 0.2em 0.2em;
    list-style-type: none;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: fade(@body-bg, 20%);
    }
  }
}


