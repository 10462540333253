@import '~consumer/styles/less/modules/variables.less';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.logo {
  height: 75px;
  width: auto;
}

.header {
  font-family: @weplayed-special-font;
  font-size: 2.3em;
  margin-bottom: 10px;
  text-align: center;
  width: 80%;
}

.info {
  font-size: 1.3em;
  text-align: center;
  width: 80%;
}

.conferenceLogos {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 60%;

  img {
    height: 30px;
    width: auto;
  }
}

.teamLogo {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 10px;

  img {
    height: 60px;
    width: auto;
  }
}

.form {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  width: 75%;

  form {
    margin-top: 10px;
    width: 100%;
  }

  label {
    font-weight: normal;
    margin-top: 7px;
    padding-right: 10px;
    text-align: right;
    vertical-align: top;
    width: 33%;
  }

  div {
    font-size: 1.2em;
    margin-bottom: 10px;
    width: 67%;
    display: inline-block;
  }

  @media(max-width: 500px) {
    label {
      text-align: left;
    }

    label,
    div {
      width: 100%;
    }
  }
}

.button {
  padding: 10px 40px !important;
}

.close {
  background: transparent;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;

  svg {
    fill: @body-bg;
  }
}