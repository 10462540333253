@import '~common/styles/variables.less';

@background: #00000055;
@color: #ffffff;

.root {
  background-color: @background;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    color: @color;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  #media-breakpoint-down(@layout-toggle, {
    padding-bottom: 0px;

    svg {
      height: 20px;
      width: 20px;
    }
  });

  // Grid items
  > div, button {
    cursor: pointer;
    height: 100%;
    padding: 5px 15px;
    border: none;
    background: transparent;

    &[disabled] svg {
      opacity: 0.5;
    }
  }
}

.time {
  margin-right: auto;
}

.live {
  left: 2em;
  position: absolute;
  top: 2em;
}
