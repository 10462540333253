@import '~consumer/styles/less/modules/variables.less';

.counter {
  color: @body-color;
  font-size: 0.9em;
  left: 100%;
  position: absolute;
  top: -3px;
}

.root {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: default;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  position: relative;

  &.editable {
    cursor: pointer;
  }
}

