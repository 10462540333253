@import '~consumer/styles/less/modules/variables.less';

@bottom-active-width: 2px;
@bottom-active-time: 0.5s;

.root {
  width: 100%;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tab {
  flex-grow: 1;
  border: 0;
  outline: 0;
  font-family: @weplayed-special-font;
  font-size: 20px;
  background: transparent;
  color: @gray-500;
  padding: 10px;
  border-bottom: @bottom-active-width solid @weplayed-primary;
  transition: border-bottom @bottom-active-time;
  text-align: center;

  &.active {
    color: @body-color;
    border-bottom: @bottom-active-width solid @weplayed-accent;
    transition: border-bottom @bottom-active-time;
  }
}


.content {
  width: 100%;
  // display: flex;
  // flex-direction: column;

  > div {
    opacity: 1;
    transition: opacity 0.3s linear;

    &.invisible {
      position: absolute;
      left: -999999px;
      opacity: 0;
    }
  }
}
