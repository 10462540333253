.avatarContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;

  > * {
    margin-right: 20px;
  }
}

.button {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
