@import '~consumer/styles/less/modules/variables.less';
@import '~consumer/styles/less/modules/mixins.less';

.root {
  align-items: center;
  background-color: @weplayed-background-secondary;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: -@app-page-padding 0;
  min-height: 90vh;

  &.wl {
    background-color: transparent;
  }
}

.content {
  margin-top: 2em;
  min-height: 90vh;
  width: 100%;

  #media-breakpoint-up(@layout-toggle, {
    width: 50%;
  });

  :global(.wl-button) {
    border-radius: 0.5em;
    bottom: 20px;
    box-shadow: 0 1px 12px rgba(0,0,0,.08);;
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: bold;
    left: 50%;
    margin-top: 2em;
    max-width: 90%;
    padding: 20px 0;
    position: fixed;
    text-align: center;
    transform: translateX(-50%);
    width: 400px;
  }
}

.description {
  padding: 1em;
  width: 100%;
}

.moment {
  padding-bottom: 70px;
}

.ads {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 1em 1em;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.ad {
  max-width: 100%;

  img {
    max-height: 3em;
    max-width: 100%;
  }
}
