@import '~consumer/styles/less/modules/variables.less';

.user-highlight {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .user-highlight-label-and-name {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-weight: bold;
    margin-left: 5px;
  }

  .user-highlight-label {
    color: @weplayed-secondary-cta;
    font-size: 10px;
    white-space: nowrap;
  }

  .user-highlight-name {
    // Uncomment this if you want to have blue text by default
    // color: @weplayed-blue-light;
    font-size: 14px;
    white-space: nowrap;
  }
}
