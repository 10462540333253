@import '~consumer/styles/less/modules/variables.less';

.searchBox {
  align-items: center;
  display: grid;
  grid-template-columns: auto 4em;
  column-gap: 1em;
  max-width: 500px;
  width: 100%;

  h1 {
    grid-column: 1 / span 2;
    text-align: center;
    margin: 0 0 10px;
  }

  &.noButton {
    grid-template-columns: auto;

    h1 {
      grid-column: 1;
    }
  }

  input {
    grid-column: 1;
    border: none;
    border-radius: 5px;
    color: @weplayed-background;
    font-size: 1.2em;
    height: 1.7em;
    outline: none;
    padding-left: 5px;
  }

  button {
    grid-column: 2;
    align-items: center;
    display: flex;
    height: 2.1em;
    justify-content: center;
    width: 4em;

    svg {
      width: 1.5em;
      fill: #fff;
    }
  }
}
