@import '~common/styles/variables.less';

@game-timeline-fade-color: fade(@body-color, 20%);

@game-timeline-scrubber-width: 13px;
@game-timeline-scrubber-border-width: 2px;

@game-timeline-scrubber-background: #1aa0db;
@game-timeline-scrubber-border-color: #fff;
@game-timeline-scrubber-line-color: #fff;
@game-timeline-scrubber-border: 2px;

@game-timeline-progress-width: 2px;

@game-timeline-marker-width: 7px;
@game-timeline-known-marker-width: 10px;
@game-timeline-marker-spacing: 5px;

@game-timeline-zoom-height: 20px;
@game-timeline-collapsed-height: 35px;
@game-timeline-periods-height: 20px;

@game-timeline-background: @weplayed-background-secondary;
@game-timeline-moment-color: lighten(@body-bg, 10%);

@keyframes pulse-moment {
  0% {
    box-shadow: 0 0 0 0 white;
  }
  70% {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

:root {
  --game-timeline-height: 120px;

  #media-breakpoint-down(@layout-toggle, {
    --game-timeline-height: 100px;
  });
}

:export {
  timeline-collapsed-height: @game-timeline-collapsed-height;
  marker-group-width: @game-timeline-known-marker-width;
  marker-spacing: @game-timeline-marker-spacing;
  marker-width: @game-timeline-marker-width;
}

.root {
  align-items: flex-start;
  background-color: @game-timeline-background;
  display: flex;
  flex-wrap: wrap;
  height: var(--game-timeline-height);
  justify-content: center;
  padding: 0 3%;

  #media-breakpoint-down(@layout-toggle, {
    padding: 0 @game-timeline-scrubber-width / 2;
  })
}

.holder {
  width: 100%;
  height: calc(var(--game-timeline-height) - @game-timeline-zoom-height);
  position: relative;
}

.momentGroups {
  height: calc(100% - @game-timeline-periods-height - 5px); // temp fix
  overflow: hidden;
  position: relative;
}

.momentGroup {
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  margin-top: @game-timeline-scrubber-width + @game-timeline-marker-spacing;
  transform: translateX(-@game-timeline-known-marker-width / 2);

  .moments {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;

    div {
      border-radius: 500em;
      border: none;
      height: @game-timeline-marker-width;
      margin-bottom: @game-timeline-marker-spacing;
      position: relative;
      text-indent: @game-timeline-marker-width;
      width: @game-timeline-marker-width;

      &::after {
        background: linear-gradient(to right, @game-timeline-moment-color, fade(@game-timeline-moment-color, 20%));
        border-radius: 500em;
        bottom: 1px;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 1px;
      }

      &.active {
        animation: pulse-moment 2s infinite;

        &::after {
          background: white;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        }
      }
    }
  }

  .counter {
    background: linear-gradient(transparent, fade(@game-timeline-background, 66%) 30%, @game-timeline-background 80%);
    box-sizing: border-box;
    color: @game-timeline-fade-color;
    font-family: sans-serif;
    font-size: @game-timeline-known-marker-width;
    height: @game-timeline-known-marker-width * 1.5;
    line-height: @game-timeline-known-marker-width;
    margin-left: -100%;
    padding-top: @game-timeline-known-marker-width * 0.5;
    pointer-events: none;
    position: relative;
    text-align: left;
    top: calc(100% - @game-timeline-scrubber-width - @game-timeline-known-marker-width * 2);
    width: 100%;

    b {
      text-align: center;
      width: @game-timeline-known-marker-width;
    }
  }
}

.progress {
  position: absolute;
  width: 100%;
  left: 0;
  top: @game-timeline-scrubber-width / 2 - @game-timeline-progress-width / 2;
  background-color: @game-timeline-fade-color;
  border-left-color: @game-timeline-scrubber-border-color;
  border-left-style: solid;
  height: @game-timeline-progress-width;
  pointer-events: none;
  touch-action: none;
}

.scrubber {
  height: calc(100% - @game-timeline-periods-height);
  margin-left: -@game-timeline-scrubber-width / 2;
  pointer-events: none;
  position: absolute;
  top: 0;
  touch-action: none;
  width: @game-timeline-scrubber-width;
  z-index: 10;

  .dot {
    background-color: @weplayed-accent;
    border-radius: 50%;
    border: solid @game-timeline-scrubber-border-width @game-timeline-scrubber-border-color;
    height: @game-timeline-scrubber-width;
    left: 0;
    pointer-events: all;
    position: absolute;
    top: 0;
    touch-action: auto;
    width: @game-timeline-scrubber-width;
  }

  .bar {
    background-color: transparent;
    border-radius: 0 0 @game-timeline-scrubber-width / 2 @game-timeline-scrubber-width / 2;
    border: solid @game-timeline-scrubber-border-width @game-timeline-fade-color;
    border-top: 0;
    bottom: 0;
    display: none;
    left: 0;
    position: absolute;
    top: @game-timeline-scrubber-width / 2 - @game-timeline-scrubber-border-width / 2;
    width: @game-timeline-scrubber-width;
  }
}

.scroller {
  width: 100%;
  height: 100%;
  position: relative;
}

:global(#clickableDiv) {
  background-color: @game-timeline-background;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  user-select: none;
  width: 100%;
}

.popupHolder {
  bottom: 95%;
  display: flex;
  justify-content: center;
  position: absolute;
  width: @game-timeline-known-marker-width;
}

.popup {
  background-color: @weplayed-background;
  border-radius: 3px;
  border: solid 1px @weplayed-secondary-cta;
  bottom: 0;
  color: @body-color;
  font-size: 10px;
  max-width: 150px;
  padding: 5px;
  position: absolute;
  white-space: nowrap;

  > span {
    display: block;
  }

  &:after, &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: @weplayed-background;
    border-width: 5px;
    margin-left: -5px;
  }

  &:before {
    border-top-color: @weplayed-secondary-cta;
    border-width: 6px;
    margin-left: -6px;
  }
}

.zoom {
  display: none;
  height: @game-timeline-zoom-height;
  margin-left: auto;
  max-width: 200px;
  width: 100%;
  align-items: center;

  input {
    padding: (@game-timeline-zoom-height / 2) 0;
  }
}

.segments {
  color: @body-color;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: (@game-timeline-scrubber-width / 2 + @game-timeline-scrubber-border-width / 2) ;
  touch-action: none;
  width: 100%;
}

.segment {
  align-items: center;
  color: @body-color;
  display: flex;
  flex-direction: column;
  font-size: @game-timeline-known-marker-width;
  height: 100%;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%);

  div {
    border-left: dotted 1px @game-timeline-fade-color;
    transform: translateX(1px);
    flex: 1;
    width: 0;
  }
}

.withGroups {
  .scrubber {
    .bar {
      display: block;
    }
  }

  .zoom {
    display: flex;
  }
}
