@import "~bootstrap-less-port/less/bootstrap.less";
@import "~react-toastify/dist/ReactToastify.css";
// slick-carousel package is required by react-slick
@import "~slick-carousel/slick/slick.less";
@import "~slick-carousel/slick/slick-theme.less";
@import "~rc-slider/assets/index.css";

@import '~videojs-contrib-ads/dist/videojs.ads.css';
@import '~videojs-contrib-ads/dist/videojs-contrib-ads.css';
@import '~videojs-ima/dist/videojs.ima.css';

@import "./modules/index.less";
