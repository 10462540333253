@import "~consumer/styles/less/modules/variables.less";

@animation-duration: .3s;

.root {
  overflow: hidden;
  position: relative;
  z-index: 0;
  transition: transform @animation-duration, z-index @animation-duration;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  div {
    background: no-repeat center center;
    background-size: cover;
    display: none;
    inset: 0 0 0 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    touch-action: none;
    transition: opacity @animation-duration;
  }

  &:hover,
  &.hover {
    transform: scale(1.05);
    transition: transform @animation-duration, z-index 0s;
    z-index: 20;

    div {
      display: block;
      opacity: 1;
    }
  }
}
