@import "~consumer/styles/less/modules/variables.less";

.root {
  margin: 20px auto;

  @media(max-width: 1600px) {
    margin-top: 0;
  }

  @media(max-width: 500px) {
    margin-top: 0;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  #media-breakpoint-down(lg, {
    flex-direction: column;
  });

  img {
    height: 30px;
    margin-right: 10px;

    #media-breakpoint-down(lg, {
      margin-right: 0;
    });
  }
}

.tabLabel {
  text-transform: none;
  font-size: 16px;
  white-space: nowrap;

  #media-breakpoint-down(sm, {
    display: none;
  });
}

.content {
  max-width: 98%;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px auto 10px;

  @media(max-width: 500px) {
    margin-top: 20px;
  }
}

.school {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 2em;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 25%;

  @media(max-width: 1600px) {
    width: 50%;
  }

  @media(max-width: 500px) {
    font-size: 1.2em;
    margin-bottom: 10px;
    width: 100%;
  }

  span {
    flex: 1;
    margin-left: 20px;
  }
}

.subconference {
  width: 50%;

  @media(max-width: 500px) {
    width: 100%;
  }

  h2 {
    font-size: 2.2em;
    margin: 20px 0;
    text-transform: uppercase;

    @media(max-width: 500px) {
      font-size: 1.5em;
      margin: 10px 0;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .school {
    width: 50%;

    @media(max-width: 1600px) {
      width: 100%;
    }

    @media(max-width: 500px) {
      width: 100%;
    }
  }
}
