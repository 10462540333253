@import "~consumer/styles/less/modules/variables.less";

.button {
  min-width: 75px;

  &:hover {
    background-color: @primary;
  }

  .following,
  .unfollow {
    display: none;
  }

  &.following {
    .follow,
    .unfollow {
      display: none;
    }

    .following {
      display: inline;
    }

    &:hover {
      .following {
        display: none;
      }

      .unfollow {
        display: inline;
      }
    }
  }
}