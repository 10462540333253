@import '~consumer/styles/less/modules/variables.less';
@import '~consumer/styles/less/modules/mixins.less';

@background: @weplayed-background;
@moment-background: @body-bg;
@moment-active-background: lighten(@moment-background, 20%);
@game-hub-header-height: 2em;

:root {
  --game-hub-header-height: @game-hub-header-height;
  --game-hub-entry-header-height: 2.5em; // h2 + padding
}

.root {
  user-select: none;
}

.disabled {
  pointer-events: none;
  touch-action: none;
  opacity: 0.3;
}

.cursorDefault {
  cursor: default !important;
}

.header {
  box-sizing: content-box;
  padding: var(--game-controls-padding);
  height: @game-hub-header-height;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px fade(@body-color, 20%);
}

.entries {
  padding-bottom: var(--game-controls-padding);

  .name {
    padding: 0.5em var(--game-controls-padding);
    margin: 0;
    background-color: fade(@weplayed-background-secondary, 75%);

    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.5em;
    }
  }
}

.clock {
  display: block;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0 0.25em;
}


.menu,
.create {
  background-color: @body-color;
  border: 0;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: 100%;
  overflow: hidden;
  padding: 0;
  text-indent: -1000px;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100%;

  &:hover {
    background-color: @body-color;
  }
}

.like {
  align-self: center;
  margin-left: var(--game-controls-padding);
  width: 1.5em;

  @media screen and (hover: hover) {
    visibility: hidden;
  }
}

.withLike .like {
  visibility: visible;
}

.menu {
  align-self: center;
  margin-left: var(--game-controls-padding);
  mask-image: url('~@mdi/svg/svg/dots-horizontal.svg');
  width: 1.5em;
  -webkit-mask-image: url('~@mdi/svg/svg/dots-horizontal.svg');

  @media screen and (hover: hover) {
    visibility: hidden;
  }
}

.create {
  mask-image: url('~@mdi/svg/svg/plus-circle-outline.svg');
  pointer-events: all;
  touch-action: all;
  width: 1em;
  -webkit-mask-image: url('~@mdi/svg/svg/plus-circle-outline.svg');
}

.floating {
  background-color: @body-color;
  border-radius: 0.25em;
  bottom: 0;
  color: @body-bg;
  display: none;
  flex-direction: row;
  font-size: 0.8em;
  line-height: 1em;
  opacity: .2;
  position: absolute;
  right: 0;

  > svg {
    height: 1em;
    margin: 0.25em;
    width: 1em;
  }
}

.withStatus .floating,
.withScore .floating {
  display: flex;
}

.status {
  display: none;
  overflow: hidden;
  text-indent: -1000px;
  width: 1em;
  position: relative;
  margin: 0.25em;

  &::after {
    background-color: transparent;
    bottom: 0;
    content: '';
    left: 0;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
  }

  &.reviewed::after {
    background-color: green;
    mask-image: url('~@mdi/svg/svg/check-circle-outline.svg');
    -webkit-mask-image: url('~@mdi/svg/svg/check-circle-outline.svg');
  }
}

.withStatus .status {
  display: block;

  &:empty {
    display: none;
  }
}

.score {
  display: none;
  margin: 0.25em;
}

.status + .score {
  margin-left: 0.25em;
}

.withScore .score {
  display: block;
}

.entry {
  border-radius: 0.25em;
  margin: 0.5em var(--game-controls-padding) 0.5em;
  padding: 0.5em 0.75em;

  &.withLink {
    opacity: 1;
  }

  .inner {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &.nav {
    background-color: @background;
  }

  &.moment {
    cursor: pointer;

    .thumbnail {
      .clock {
        background-color: @weplayed-background-secondary;
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    &.active {
      background-color: @moment-active-background;
      box-shadow: 1px 2px 2px fade(black, 20%);

      .clock {
        background-color: @moment-active-background;
      }

    }

    &:hover:not(.active) {
      background-color: darken(@moment-active-background, 15%);

      .floating {
        opacity: 1;
      }

      .clock {
        background-color: darken(@moment-active-background, 15%);
      }
    }

    &:hover, &.active {
      .menu, .like {
        visibility: visible;
      }
    }
  }
}

.thumbnail {
  margin-right: 1em;
  min-height: 100px * @video-proportions;
  min-width: 100px;
  position: relative;
  width: 100px;

  .tile-bg(200);

  img {
    border: solid 0.5px @body-bg;
    width: 100%;
  }
}


.summary {
  flex: 1;
  font-size: 0.9em;
  margin-right: 0.8em;
}

.createMoment {
  background-color: transparent;
  border: 0;
  height: 1em;
  margin-left: 1em;
  padding: 0;
  width: 1em;

  svg {
    height: 100%;
    width: 100%;
  }
}

.children:not(:empty) {
  margin-top: 10px;
}

.segments {
  .small {
    display: block;
  }

  .active {
    background-color: @weplayed-accent;
  }
}

.sort {
  background-color: @weplayed-background-secondary;
  color: @body-color;
  border-radius: 0.3em;
  margin-right: 0.1em;
}

.segment {
  position: static;
}

.autoscroll {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  padding: 0.25em var(--game-controls-padding);
  user-select: none;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  label {
    font-size: 0.8em;
  }
}

.formButtons {
  display: flex;
  padding: 1em 0.5em 0.5em;
  justify-content: space-around;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0.8em;
  padding: 0.5em 0 0;

  input {
    margin-top: 0.1rem;
    margin-left: -1.1rem;
  }
}

.dropdown {
  z-index: 20;
}

.placeholder svg {
  height: 1em;
}
