@import "~consumer/styles/less/modules/variables.less";
@import "~consumer/styles/less/modules/moment-presenter.less";

.root {
  position: relative;

  :global(body.wl) & {
    margin: 5px;
    box-shadow: 0 1px 12px rgba(0,0,0,.08);
  }
}

.description {
  :global(body.wl) & {
    margin: 0;
    padding: 10px;
  }
}

.poster {
  filter: brightness(40%);
  height: auto;
  width: 100%;
}

.headline {
  background-color: @weplayed-foreground;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.divider {
  border-top: 1px solid #404A51;
}

.player {
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.placeholder {
  background-image: url(~images/bg/foot-cover.jpg);
  background-position: center center;
  background-size: cover;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;

  &:global(.MCBASK),
  &:global(.WCBASK) {
    background-image: url(~images/bg/bask-cover.jpg);
  }

  &:global(.MCFENC),
  &:global(.WCFENC) {
    background-image: url(~images/bg/fencing-cover.jpg);
  }

  &:global(.MCSOCC),
  &:global(.WCSOCC) {
    background-image: url(~images/bg/socc-cover.jpg);
  }

  &:global(.MCLAX),
  &:global(.WCLAX) {
    background-image: url(~images/bg/lacr-cover.jpg);
  }

  &:global(.MCVOLL),
  &:global(.WCVOLL) {
    background-image: url(~images/bg/voll-cover.jpg);
  }

  &:global(.MCTENN),
  &:global(.WCTENN) {
    background-image: url(~images/bg/tenn-cover.jpg);
  }

  &:global(.MCHOCK),
  &:global(.WCHOCK) {
    background-image: url(~images/bg/hock-cover.jpg);
  }

  &:global(.MCBASE),
  &:global(.WCSOFT) {
    background-image: url(~images/bg/soft-cover.jpg);
  }

  &:global(.MCWPOLO),
  &:global(.WCWPOLO) {
    background-image: url('~images/bg/wpolo-cover.jpg');
  }

  &:global(.WCFHOC) {
    background-image: url(~images/bg/fieldhockey-cover.jpg);
  }

  &:global(.MCROW),
  &:global(.WCROW) {
    background-image: url('~images/bg/rowing-cover.jpg');
  }

  &:global(.MCWRSTL),
  &:global(.WCWRSTL) {
    background-image: url('~images/bg/wrstl-cover.jpg');
  }

  &:global(.WCACRO) {
    background-image: url('~images/bg/acro-cover.jpg');
  }

  &:global(.MCSWIM),
  &:global(.WCSWIM) {
    background-image: url('~images/bg/swim-cover.jpg');
  }
}

.adjustContainer {
  margin: 1em 0;
}

.videoTrimmer {
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
}

.button {
  height: 2.5em;
  margin: 0 1em;
}

.controls {
  display: flex;
  flex-direction: row;
  left: 50%;
  position: absolute;
  top: 70%;
  transform: translate(-50%);

  > button {
    background-color: @weplayed-background-semitransparent;
    border-radius: 0.5em;
    border: 0;
    cursor: pointer;
    height: 40px;
    margin-right: 10px;
    padding: 10px;
    position: relative;
    width: 40px;

    &:last-child {
      margin-right: 0;
    }

    #media-breakpoint-down('sm', {
      height: 30px;
      padding: 3px;
      width: 30px;
    });

    > svg {
      height: 100%;
      width: 100%;
    }
  }
}