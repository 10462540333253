@import "~consumer/styles/less/modules/variables.less";

.summary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px auto;
  max-width: 500px;

  .photo {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0.66;
    justify-content: center;
    max-width: 50%;
    overflow: hidden;

    > img {
      object-fit: cover;
      max-height: 145px;
      height: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1.33;
    padding-left: 20px;

    h1 {
      margin-top: 0;
    }

    @media(max-width: 500px) {
      padding-left: 10px;
    }
  }

  .actions {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;

    .bio {
      align-items: center;
      display: inline-flex;
      padding-right: 2px;

      svg {
        height: 20px;
      }
    }
  }

  .follow {
    text-align: right;
  }

  .bioContent {
    width: 100%;
    overflow: hidden;
    transition: max-height 200ms ease-out;

    > div {
      // do not allow collapse margins for correct measuring
      overflow: hidden;
    }
  }

  @media(max-width: 500px) {
    margin: 10px;
  }
}

.tabs {
  background-color: @weplayed-background;
  top: 0;
  position: sticky;
  z-index: 2;
  margin: 0 auto 20px;
  width: 100%;
  padding: 0 20%;

  #media-breakpoint-down('md', {
    padding: 0 20px;
  });
}

.tab {
  position: relative;

  .manage {
    position: absolute;
    right: 0;
    top: 12px;
  }
}


.sport {
  font-size: 13px;
}

.content {
  overflow: hidden;

  h1 {
    text-align: center;
  }

  @media(max-width: 500px) {
    margin: 20px 0;
  }
}

.topMomentHolder {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.channel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
}
