@import '~consumer/styles/less/modules/variables.less';

.root {
  width: 100%;
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
  width: 100%;

  img,
  svg {
    height: 24px;
    width: 24px;
  }
}

.links {
  margin-top: 20px;
  text-align: center;
  text-decoration: underline;

  :nth-child(2) {
      margin-left: 20px;
  }
}

