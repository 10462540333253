.container {
  width: 100%;
}

.categories,
.category {
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    list-style-type: none;
  }
}

.back {
  cursor: pointer;

  span {
    vertical-align: 3px;
  }
}

.iconLink {
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 60px;

  span {
    display: block;
    font-size: 0.8em;
    text-align: center;
    width: 100%;
  }
}

.primitives {
  .iconLink {
    cursor: grab;
  }
}

.icon {
  width: 60px;
  height: 60px;
  object-position: center center;
  object-fit: contain;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.selected {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}