.root {
  position: relative;

  h1 {
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 4em;
    display: flex;
    flex-direction: row;
    margin: 60px 0 40px;

    @media(max-width: 1600px) {
      width: 90%;
      font-size: 2.5em;
      margin: 30px auto;
    }

    span {
      margin-left: 20px;
    }
  }

  > a {
    position: absolute;
    left: 32px;
    top: 32px;
    display: none;

    svg {
      height: 32px;
      width: 32px;
    }

    @media(max-width: 1600px) {
      display: block;
    }
  }
}
