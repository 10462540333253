@import '~consumer/styles/less/modules/variables.less';

@size: 20px;

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.entry {
  display: flex;
  flex-direction: row;

  > span:first-child {
    min-width: @size;
    min-height: @size;
    width: @size;
    height: @size;
    margin-right: 10px;
    padding: 0;
    position: relative;
    text-align: center;
    // border: solid 1px @weplayed-modal-text;


    &:after {
      color: @body-bg;
      content: '✔';
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: @size * 0.8;
      line-height: @size;
    }

    &.multiple::after {
      opacity: .2;
    }

    &.checked::after {
      opacity: 1;
    }


    input {
      margin: 0;
      cursor: pointer;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

