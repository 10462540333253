@import '~consumer/styles/less/modules/variables.less';

.container {
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .image {
    background-color: transparent;
    border-radius: 5px;
    height: 85%;
    object-fit: contain;
    object-position: center;
    width: 85%;
  }

  &.xxsmall {
    height: 20px;
    width: 20px;
  }

  &.xsmall {
    height: 30px;
    width: 30px;
  }

  &.small {
    height: 40px;
    width: 40px;
  }

  &.normal {
    height: 65px;
    width: 65px;
  }

  &.large {
    height: 90px;
    width: 90px;
  }

  &.xlarge {
    height: 120px;
    width: 120px;
  }

}

@media(max-width: @grid-float-breakpoint) {
  .container {

    &.xsmall {
      height: 30px;
      width: 30px;
    }

    &.small {
      height: 30px;
      width: 30px;
    }

    &.normal {
      height: 40px;
      width: 40px;
    }

    &.large {
      height: 50px;
      width: 50px;
    }

    &.xlarge {
      height: 80px;
      width: 80px;
    }
  }
}
