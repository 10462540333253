@import "~consumer/styles/less/modules/variables.less";
@import "~consumer/styles/less/modules/mixins.less";

.root {
  .tile-root();

  > a {
    .no-hover-effect();
    .tile-image();

    display: block;

    > div:first-child {
      align-items: center;
      display: flex;
      justify-content: space-around;
      padding-top: 8%;

      > span {
        font-size: 1.1em;
      }
    }
  }
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;

  img {
    object-fit: contain;
    object-position: center;
    height: 80px;
    width: 80px;

    #media-breakpoint-down(@layout-toggle, {
      width: 50px;
      height: 50px;
    });
  }

  span {
    font: bold 1.5em/1em @weplayed-special-font;
    margin-top: 10px;

    #media-breakpoint-down(@layout-toggle, {
      font-size: 1em;
    });
  }
}

.live .team {
  span {
    display: none;
  }
}

.date, .extra {
  font-size: 1.1em;
  position: absolute;
  bottom: 10px;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 0.8em;
  });
}

.date {
  left: 20px;
}

.extra {
  right: 20px;
  max-width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
}
