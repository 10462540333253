@import '~cms/styles/variables.less';

.root {
  // for GameTimeline
  overflow: hidden;
}

.player {
  position: relative;
}

.disabled {
  pointer-events: none;
  touch-action: none;
}

.actions {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  > button {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    background-position: center -11.5px;
    background-repeat: no-repeat;
    background-size: 90%;
    border-radius: 10px;
    border: none;
    color: white;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: flex-start;
    margin-right: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 18px;

    &.create {
      background-image: url('~images/pulse.svg');
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }
}

.trimmer {
  align-items: flex-end;
  background-color: @weplayed-background-secondary;
  display: flex;
  flex-direction: column;
  height: var(--game-timeline-height);
  justify-content: flex-start;
  position: relative;
}

.loop {
  align-items: center;
  display: flex;
  flex-direction: row;

  input {
    margin-right: 5px;
  }
}
