@import '~consumer/styles/less/modules/variables.less';

@game-headline-height: 40px;

:root {
  --game-headline-height: @game-headline-height;
}

.root {
  align-items: center;
  background-color: @game-headline-background;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  height: @game-headline-height;
  justify-content: space-around;
}

.home {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
  touch-action: none;
}

.score {
  transform: scale(0.8);
}

.toggle {
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 0;
  position: absolute;
  right: 0.7em;
  top: 0;
  width: 2em;

  svg {
    fill: @body-color;
    height: 24px;
    width: 24px;
  }
}

.container {
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 20;
}

.menu {
  background-color: @game-headline-background;
  border-radius: @border-radius;
  border: solid 1px @body-bg;
  box-shadow: 1em 1em 1em @body-bg;
  display: block;
  margin: 0;
  padding: 1em;

  #media-breakpoint-down(@layout-toggle, {
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
  });

  li {
    align-items: center;
    color: @body-color;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style: none;
    padding-bottom: 0.5em;

    &:last-child {
      padding-bottom: 0;
    }

    &.divider {
      border-top: solid 1px @body-bg;
      margin: 0 -1em;
    }

    > label {
      flex: 1;
      margin: 0 1em 0 0;
      white-space: nowrap;
    }
  }

  .control {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    select {
      background-color: @body-bg;
      color: @body-color;
    }

    :global(.custom-switch) {
      transform: translateY(-7px);
    }
  }
}
