@import "~consumer/styles/less/modules/variables.less";
@import "~consumer/styles/less/modules/mixins.less";

.root {
  height: @tile-desktop-medium-height;
  margin-right: @tile-desktop-medium-spacing;
  position: relative;
  width: @tile-desktop-medium-width;

  @media (max-width: 500px) {
    margin-right: @tile-mobile-medium-spacing;
    height: @tile-mobile-medium-height;
    width: @tile-mobile-medium-width;
  }
}

.image {
  .absolute-full(-1);

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
    border-radius: @tile-border-radius;
  }

  &:after {
    .absolute-full();
    background-color: black;
    content: '';
    opacity: 0.6;
  }
}

.content {
  .no-hover-effect();

  align-items: center;
  border-radius: @tile-border-radius;
  background-color: @weplayed-foreground;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px;

  h3 {
    font-size: 2em;
    font-weight: 500;
    line-height: 1em;
    margin: 0;
    overflow-wrap: break-word;
    position: relative;
    text-align: left;
    width: 100%;

    white-space: nowrap;
    overflow: hidden;

    &:after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), @weplayed-foreground);
      bottom: 0;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
    }

    @media (max-width: 500px) {
      font-size: 1.5em;
    }

  }
}

.image + .content {
  background-color: transparent;

  h3 {
    white-space: inherit;
    overflow: inherit;

    &:after {
      display: none;
    }
  }
}

.moments {
  color: @weplayed-overlay-text-light;
  overflow-wrap: break-word;
  width: 100%;
  font-size: 0.9em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.sport {
  font-size: 1.5em;

  @media(max-width: 500px) {
    font-size: 1.2em;
  }
}

.root:global(.small) {
  height: @tile-desktop-small-height;
  width: @tile-desktop-small-width;
  margin: 0 5px 5px 0;

  @media (max-width: 500px) {
    height: @tile-mobile-small-height;
    width: @tile-mobile-small-width;
  }

  .content {
    h3 {
      font-size: 1.6em;
      line-height: 1.2em;

      @media (max-width: 500px) {
        font-size: 1.2em;
        line-height: 1.2em;
      }
    }
  }

}