@import '~consumer/styles/less/modules/variables.less';

.tile-list {
  margin-right: -@tile-desktop-medium-spacing;
  min-height: 100px;
  min-width: 100px;
  padding: 0;

  @media(max-width: 500px) {
    margin-right: -@tile-mobile-medium-spacing;
  }

  .tile-list-container {
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
  }

  .tile-list-spacer {
    margin-bottom: 30px;
  }

  .channel-link {
    color: @body-color;
    display: block;
    width: 380px;
    padding-right: 20px;

    @media(max-width: 768px) {
      width: 280px;
    }
  }
}