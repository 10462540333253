@import '~consumer/styles/less/modules/variables.less';
@import '~consumer/styles/less/modules/mixins.less';

:root {
  --header-height: @header-height;

  #media-breakpoint-down(@layout-toggle, {
    --header-height: @header-height-mobile;
  });
}

.root {
  background-color: @header-background;
  height: var(--header-height);
  max-height: var(--header-height);
  position: relative;
  width: 100%;
  z-index: @header-zindex;

  #media-breakpoint-up(@layout-toggle, {
    &.tiny {
      height: @tiny-hav-size;
      position: sticky;
      top: 0;

      &:hover,
      &.tinyHover {
        .container {
          transition: none;
          bottom: calc(-1 * var(--header-height) + @tiny-hav-size);
        }
      }

      &:hover {
        .container {
          transition: bottom .3s;
        }
      }
    }
  });
}

.container {
  background-color: @header-background;
  bottom: 0;
  color: @header-color;
  display: flex;
  flex-direction: row;
  font: @header-font;
  height: var(--header-height);
  max-height: var(--header-height);
  position: absolute;
  text-transform: uppercase;
  transition: bottom .3s .3s;
  white-space: nowrap;
  width: 100%;

  button {
    align-items: center;
    background: transparent;
    border: none;
    color: @header-color;
    display: flex;
    justify-content: center;
    text-transform: uppercase;

    &:hover {
      color: @header-accent-color;
    }

    &:focus + ul {
      max-height: 200px;
      transition: max-height @header-animation-duration;
    }
  }

  svg {
    fill: @body-color;
  }

  a {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 0 15px;

    svg {
      margin-right: 10px;
    }

    &:hover {
      color: @header-accent-color;

      svg {
        fill: @header-accent-color;
      }
    }
  }

  svg {
    flex: 1;
  }

  ul {
    align-items: stretch;
    background-color: @header-background;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0 15px 0 auto;
    padding: 0;
    transition: max-height @header-animation-duration;

    #media-breakpoint-down(@layout-toggle, {
      flex-direction: column;
      left: 0;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
      transition: max-height @header-animation-duration;
      width: 100%;

      > li:last-child {
        margin-bottom: 10px;
      }
    });

    ul {
      flex-direction: column;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 100%;
      transition: max-height @header-animation-duration 100ms;

      #media-breakpoint-down(@layout-toggle, {
        max-height: inherit !important;
        position: static;
      });

      li {
        justify-content: flex-start;
      }
    }
  }

  &.open {
    #media-breakpoint-down(@layout-toggle, {
      ul {
        max-height: 500px;
      }
    });
  }

  li {
    align-items: stretch;
    display: flex;
    justify-content: center;

    #media-breakpoint-down(@layout-toggle, {
      a {
        justify-content: flex-start;
        padding: 10px 20px;

        svg {
          flex: 0 auto;
        }
      }
    });

    li a, li button {
      flex: 1;
      display: block;
      padding: 10px 20px;
      text-align: left;

      svg {
        margin-right: 10px;
      }

      #media-breakpoint-down(@layout-toggle, {
        padding: 10px;
      });
    }
  }

  .menu {
    display: none;
    height: var(--header-height);
    width: var(--header-height);

    #media-breakpoint-down(@layout-toggle, {
      display: block;
    });
  }

  .brand {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-left: 10px;

    #media-breakpoint-down(@layout-toggle, {
      flex: 1;
      padding-left: 0;
    });

    a {
      display: flex;
      flex: 1;
      margin: 0;
      padding: 0;

      &:hover {
        svg {
          fill: @header-color;
        }

        svg :global(.play) {
          fill: @header-accent-color;
        }
      }
    }

    svg {
      height: 80%;
      max-height: 80%;
      max-width: 250px;
      width: 100%;
    }
  }

  .back {
    align-self: center;
    display: flex;
    flex-direction: column;
    flex: 0 auto;
    padding: 3px 5px;

    span {
      display: block;
      font-size: 0.5em;
      line-height: 1.2em;
      width: 100%;
    }

    svg {
      margin-left: 0;
    }
  }

  .home {
    margin-left: auto;

    #media-breakpoint-down(@layout-toggle, {
      margin-left: 0;
    });

    img {
      margin: 15px 10px 15px 0;
      max-height: 50px;

      #media-breakpoint-down(@layout-toggle, {
        display: none;
      });
    }
  }

  .team {
    margin-right: auto;
  }

  .search {
    display: flex;

    svg {
      height: calc(var(--header-height) * 0.6);
      margin-right: 0;
      width: calc(var(--header-height) * 0.6);
    }

    #media-breakpoint-down(@layout-toggle, {
      display: none;
    });
  }

  > .search {
    display: none;
    flex: 0;

    #media-breakpoint-down(@layout-toggle, {
      display: flex;
    });
  }

  .profile {
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    position: relative;

    #media-breakpoint-down(@layout-toggle, {
      > button {
        display: none;
      }
    });
  }
}