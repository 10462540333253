.description {
  display: inline-block;
  font-size: 1.2em;
  line-height: 1.8em;
  margin-top: 5px;
  text-align: left;
  width: 100%;

  @media (max-width: 500px) {
    font-size: 1.0em;
  }


  :global(.user-highlight) {
    margin-right: 10px;
    vertical-align: -10px;

    .user-highlight-name {
        font-size: 1.1em;
    }
  }
}

