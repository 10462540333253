@columnWidth: 250px;
@seasonsPadding: 50px;

.root {
  h2 {
    font-size: 2.5em;
    margin: 0 0 40px;
  }

  > h2 {
    display: none;

    @media (max-width: @columnWidth * 2) {
      display: block;
    }
  }

  li {
    list-style-type: none;
  }
}

.all {
  display: none;
  margin: 0 auto;
  width: @columnWidth;
}

.seasons {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  justify-content: space-evenly;

  max-width: @columnWidth * 2 + @seasonsPadding;

  @media (min-width: @columnWidth * 3 + @seasonsPadding * 3) { // one more seasonsPadding to not stick into borders
    max-width: @columnWidth * 3 + @seasonsPadding * 2;
  }
}

.season {
  margin-top: 20px;
}

.teams {
  margin: 0;
  padding: 0;

  li {
    font-size: 1.7em;
    margin-bottom: 40px;

    @media(max-width: 1600px) {
      margin-bottom: 20px;
    }

    &.unavailable {
      opacity: 0.6;
    }
  }

  img {
    margin-right: 10px;
    max-height: 1.5em;
  }
}

@media (max-width: @columnWidth * 2 + @seasonsPadding - 1) {
  .all {
    display: block;
  }

  .seasons {
    display: none;
  }
}