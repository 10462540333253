@import '~consumer/styles/less/modules/variables.less';
@import '~consumer/styles/less/modules/mixins.less';

@dotWidth: 1.5em;

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: @header-zindex + 1;
}

.actions {
  align-items: center;
  background-color: #0F0B0B;
  color: white;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: space-around;
  min-height: 60px;
  width: 100%;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 1.0em;
  });
}

.name {
  align-items: center;
  display: flex;
  font-size: 25px;
  justify-content: center;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 20px;
  });

  #media-breakpoint-down('xs', {
    width: 100%;
  });
}

.counter {
  align-items: center;
  cursor: default;
  display: flex;
  font-size: 18px;
  justify-content: center;

  span {
    margin-right: 5px;

    &.bold {
      font-weight: bold;
    }
  }

  svg {
    display: none;
    height: 2em;
    margin-bottom: -5px;
    width: 2em;
  }
}

.action {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 0;
  position: relative;
  top: 3px;

  button:not([disabled]):hover {
    color: @weplayed-blue-light;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.creator {
  align-items: center;
  display: flex;
  justify-content: center;
}

&.dedicated {
  .game {
    align-items: center;
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }

  .counter svg {
    display: inline-block;
  }

  #media-breakpoint-up('lg', {
    .name {
      padding-right: 310px;
    }
  });

  #media-breakpoint-up(@layout-toggle, {
    .name {
      bottom: 100%;
      font-size: 2em;
      font-weight: bold;
      height: @header-height;
      line-height: 1em;
      padding-left: 310px;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  });

  &:not(.scrolled) {
    #media-breakpoint-up(@layout-toggle, {
      .game {
        justify-content: flex-end;
        margin-left: auto;
        margin-right: (@dotWidth / 2);
      }

      .counter {
        justify-content: flex-start;
        margin-left: (@dotWidth / 2);
        margin-right: auto;
        position: relative;

        &:before {
          content: '・';
          left: -@dotWidth;
          opacity: 0.5;
          position: absolute;
          text-align: center;
          width: @dotWidth;
        }
      }
    });
  }

  &.scrolled,
  :global(body.wl) & {
    #media-breakpoint-up(@layout-toggle, {
      .game {
        flex: 1;
        order: 1;
      }

      .name {
        flex: 1;
        font-size: 1em;
        height: auto;
        order: 2;
        padding: 0;
        position: static;
      }

      .counter {
        flex: 1;
        order: 3;
        text-align: right;
      }
    });
  }
}
