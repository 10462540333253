@import "~consumer/styles/less/modules/variables.less";

.page-welcome {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin: -@app-page-padding 0;

  .back-holder {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .back {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: opacity 0.5s linear;
  }

  h1 {
    align-items: center;
    text-align: center;
    font-size: 350%;
    flex: 1;
    display: inline-flex;
  }

  h2 {
    flex: 0.3;
    margin: 0 20%;
    font: bold 2em sans-serif;
    text-align: center;
  }

  h3 {
    flex: 0.3;
    margin: 0 20%;
    font: bold 1.5em sans-serif;
    text-align: center;
  }

  .play-video {
    flex: 0.5;

    button {
      background-size: 60px 60px;
      background: url(~images/play_button.png) left center no-repeat;
      border: 0;
      color: @body-color;
      display: block;
      font: bold 1.5em sans-serif;
      padding: 10px 20px 10px 60px;

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        & {
          background: url(~images/play_button@2x.png) left center no-repeat;
          background-size: 60px 60px;
        }
      }
    }
  }

  .join-team {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;

    span {
      display: block;
      margin: 5px;
    }

    button {
      background-color: @weplayed-accent;
      font-size: 2em;
      padding: 0.2em 0;
      width: 300px;
    }
  }

  @media(max-width: 500px) {
    h1 {
      font-size: 200%;
    }

    h3 {
      margin: 0 10%;
      font-size: 1.5em;
    }

    .join-team button {
      padding: 0.2em 0;
    }
  }
}
