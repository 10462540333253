@import '~consumer/styles/less/modules/variables.less';

.root {
  align-items: center;
  background-color: @weplayed-foreground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;
  position: relative;

  #media-breakpoint-down(@layout-toggle, {
    padding: 0 5px 5px;
  });

  > a {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

}

.header {
  align-items: center;
  display: flex;
  font-size: 1.5em;
  justify-content: space-between;
  width: 100%;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 1.2em;
  });

  h2 {
    font: 300 1em @weplayed-text-font;
    margin: 0;
    padding: 0;
  }
}

.top {
  background-color: @weplayed-background;

  #media-breakpoint-up(@layout-toggle, {
    display: none;
  });
}

.live .top {
  display: none;
}

.body {
  position: relative;
}

.cover {
  background-image: url('~images/bg/foot-cover.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:global(.WCBASK),
  &:global(.MCBASK) {
    background-image: url('~images/bg/bask-cover.jpg');
  }

  &:global(.WCFENC),
  &:global(.MCFENC) {
    background-image: url('~images/bg/fencing-cover.jpg');
  }

  &:global(.WCSOCC),
  &:global(.MCSOCC) {
    background-image: url('~images/bg/socc-cover.jpg');
  }

  &:global(.WCLAX),
  &:global(.MCLAX) {
    background-image: url('~images/bg/lacr-cover.jpg');
  }

  &:global(.MCVOLL),
  &:global(.WCVOLL) {
    background-image: url('~images/bg/voll-cover.jpg');
  }

  &:global(.MCTENN),
  &:global(.WCTENN) {
    background-image: url('~images/bg/tenn-cover.jpg');
  }

  &:global(.MCHOCK),
  &:global(.WCHOCK) {
    background-image: url('~images/bg/hock-cover.jpg');
  }

  &:global(.MCBASE),
  &:global(.WCSOFT) {
    background-image: url('~images/bg/soft-cover.jpg');
  }

  &:global(.MCWPOLO),
  &:global(.WCWPOLO) {
    background-image: url('~images/bg/wpolo-cover.jpg');
  }

  &:global(.WCFHOC) {
    background-image: url('~images/bg/fieldhockey-cover.jpg');
  }

  &:global(.MCROW),
  &:global(.WCROW) {
    background-image: url('~images/bg/rowing-cover.jpg');
  }

  &:global(.MCTF),
  &:global(.WCTF) {
    background-image: url('~images/bg/track-field-cover.jpg');
  }

  &:global(.MCWRSTL),
  &:global(.WCWRSTL) {
    background-image: url('~images/bg/wrstl-cover.jpg');
  }

  &:global(.WCACRO) {
    background-image: url('~images/bg/acro-cover.jpg');
  }

  &:global(.MCSWM),
  &:global(.WCSWM) {
    background-image: url('~images/bg/swim-cover.jpg');
  }

  img {
    width: 100%;
    height: auto;
    opacity: 0;
  }
}

.cover.withCover img {
  opacity: 1;
}


.shim {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.6) 75%, rgba(0, 0, 0, 0.7) 84%, rgba(0, 0, 0, 0.8) 100%);
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
}

.extra {
  background-image: linear-gradient(#00000000 0, #00000088 50%, #00000088 100%);
  bottom: 0;
  color: white;
  font-family: @weplayed-special-font;
  font-size: 1.5em;
  left: 0;
  overflow: hidden;
  padding: 10px 20% 0;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 1.1em;
    display: none;
  });
}

.live .extra {
  display: block;
}

.score {
  align-items: center;
  display: none;
  flex-direction: column;
  height: 80%;
  justify-content: space-around;
  padding-bottom: 10px;
  padding-top: 10px;
  position: absolute;
  right: 0;
  top: 10%;
  width: 30%;

  #media-breakpoint-up(@layout-toggle, {
    display: flex;
  });
}

.live .score {
  display: flex;
}

.team {
  align-items: center;
  display: flex;
  flex-direction: column;
  font: 500 1.2em/1em @weplayed-special-font;
  height: 40%;
  justify-content: center;
  width: 100%;

  #media-breakpoint-down(@layout-toggle, {
    font-size: 0.8em;
  });
}

.teamImage {
  height: 50%;
  object-fit: contain;
  width: 100%;
}

.teamName {
  padding-top: 10%;
  white-space: nowrap;
}

.teamScore {
  font-size: 1.3em;
  padding-top: 3%;
}

.at {
  align-items: center;
  display: flex;
  font: 1.3em @weplayed-special-font;
  height: 20%;
  justify-content: center;
}

.live {
  .score {
    flex-direction: row;
    width: 100%;

    #media-breakpoint-down(@layout-toggle, {
      height: 65%;
      top: 25%;
    });
  }

  .team {
    height: 100%;
    width: 30%;
    margin-right: 10%;

    &:first-child {
      margin-left: 10%;
      margin-right: 0;
    }
  }

  .teamScore {
    display: none;
  }

  .at {
    width: 20%;
    font-size: 2.5em;

    #media-breakpoint-down(@layout-toggle, {
      font-size: 1.3em;
    });
  }
}

.liveButton {
  left: 5%;
  position: absolute;
  top: 8%;
}